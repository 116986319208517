<template>
  <b-card
    no-body
  >
    <b-overlay
      :show="show"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <b-card-body>
        <div class="card">
          <div class="card-body">
            <div>
              <h2 class="text-dark font-medium-3">
                <i class="fa fa-search" /> ค้นหา
              </h2>
            </div>
            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label for="username">Keyword</label>
                  <input
                    id="username"
                    v-model="search_val"
                    type="text"
                    class="form-control"
                    placeholder="ค้นหาด้วยเลขบัญชี หรือ ชื่อบัญชี"
                    @keyup.enter="getData()"
                  >
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="dateStart">วันที่เริ่มต้น</label>
                  <flat-pickr
                    v-model="dateStart"
                    class="form-control"
                    placeholder="เลือกเวลาเริ่มต้น"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', time_24hr: true }"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="dateEnd">วันที่สิ้นสุด</label>
                  <flat-pickr
                    v-model="dateEnd"
                    class="form-control"
                    placeholder="เลือกเวลาสิ้นสุด"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', time_24hr: true }"
                  />
                </div>
              </div>
              <div class="col-md-2 mt-2">
                <div class="form-group">
                  <button
                    class="btn btn-gradient-dark"
                    @click="getData()"
                  >
                    <feather-icon icon="SearchIcon" />
                    ค้นหา
                  </button>
                </div>
              </div>
              <div class="col-md-2 mt-2">
                <b-form-group
                  class="mr-1 mb-md-0"
                >
                  <b-button
                    v-b-modal.modal-1
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="gradient-primary"
                    block
                    @click="addData"
                  >
                    เพิ่มบัญชีถอนมือ
                  </b-button>
                </b-form-group>
              </div>
            </div>
          </div>
        </div></b-card-body>
      <b-table
        striped
        hover
        responsive
        class="position-relative items-center"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="EditIcon"
              size="16"
              class="mx-1"
              @click="editData(data.item)"
            />
            <b-tooltip
              title="Preview Invoice"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            />
          </div>
          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="TrashIcon"
              size="16"
              class="mx-1"
              @click="deleteData(data.item.id)"
            />
            <b-tooltip
              title="Preview Invoice"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            />
          </div>
        </template>
        <template #cell(addcredit)="data">
          <div class="text-nowrap">
            <b-button
              :id="`invoice-row-${data.item.id}-add-credit-button`"
              variant="success"
              size="sm"
              class="mx-1"
              @click="openAddCreditModal(data.item.id)"
            >
              ฝากเข้า
            </b-button>
          </div>
        </template>

      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
      <!-- เพิ่มบัญชี -->
      <b-modal
        ref="my-modal"
        title="กรุณากรอกข้อมูล"
        size="sm"
        ok-title="ตกลง"
        cancel-title="ยกเลิก"
        no-close-on-backdrop
        @ok="SubmitData"
      >
        <b-form @submit.prevent="SubmitData"> <!-- Add @submit.prevent to form -->
          <div>
            <b-form-group>
              <label for="dividend">ชื่อบัญชี <span class="text-danger" /></label>
              <b-input-group>
                <b-form-input
                  v-model="accname"
                  type="text"
                />
              </b-input-group>
            </b-form-group>

            <b-form-group>
              <label for="dividend">เลขที่บัญชี <span class="text-danger">* ไม่มี -</span></label>
              <b-input-group prepend="">
                <b-form-input
                  v-model="accno"
                  type="number"
                />
              </b-input-group>
            </b-form-group>

            <b-form-group>
              <label for="bankSelect">ธนาคาร <span class="text-danger">*</span></label>
              <b-form-select
                v-model="bank_code"
                :options="banklist"
                value-field="bankcode"
                text-field="name"
                required
              />
            </b-form-group>
          </div>
        </b-form>
      </b-modal>

      <!-- ADD Credit -->
      <b-modal
        ref="my-modal-addcredit"
        title="โยกเงินเข้าบัญชี"
        size="sm"
        ok-title="ตกลง"
        cancel-title="ยกเลิก"
        no-close-on-backdrop
        @ok="SubmitAddcredit()"
      >
        <div>
          <b-form-group>
            <label for="dividend">จำนวนเงิน <span class="text-danger" /></label>
            <b-input-group>
              <b-form-input
                v-model="addcredit_amount"
                type="text"
              />
            </b-input-group>
          </b-form-group>

          <!-- Note section -->
          <b-form-group>
            <label for="note">หมายเหตุ <span class="text-muted">(ถ้ามี)</span></label>
            <b-input-group>
              <b-form-input
                v-model="addcredit_note"
                type="text"
                placeholder="ระบุหมายเหตุ"
              />
            </b-input-group>
          </b-form-group>
        </div>
      </b-modal>

    </b-overlay>
  </b-card>

</template>

<script>
// import vSelect from 'vue-select'
// import moment from 'moment-timezone'
import {
  BModal, BCard, BTable,
  BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput,
  // BInputGroupAppend,
  BButton, BCardBody, VBToggle,
  BOverlay, BIconController,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment-timezone'

export default {
  components: {
    // vSelect,
    flatPickr,
    BModal,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BIconController,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      depositdata: [],
      bankData: '',
      bankname: '',
      bankcode: '',
      optionsMember: '',
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'accno', label: 'เลขบัญชี' },
        { key: 'accname', label: 'ชื่อ-สกุล' },
        { key: 'bank', label: 'ธนาคาร' },
        {
          key: 'balance',
          label: 'ยอดเงินคงเหลือ',
          formatter: value => value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
        { key: 'addcredit', label: 'ฝากเงินเข้า' },
        {
          key: 'use_credit_today',
          label: 'ยอดถอน (ตามเวลาที่เลือก)',
          formatter: value => value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
        { key: 'actions', label: 'Action' },
      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      show: false,
      accname: '',
      accno: '',
      bank_code: '',
      banklist: [],
      addcredit_amount: null,
      addcredit_note: '',
      add_id: '',
      edit_id: null,
      dateStart: moment().tz('Asia/Bangkok').format('YYYY-MM-DD 00:00:00'),
      dateEnd: moment().tz('Asia/Bangkok').format('YYYY-MM-DD 23:59:59'),
      search_val: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.getData()
    this.Get_bank()
  },
  methods: {
    getData() {
      this.show = true
      this.$http
        .get(`/bank/manual/index?search_val=${this.search_val}&dateStart=${this.dateStart}&dateEnd=${this.dateEnd}`)
        .then(response => {
          this.show = false
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    openAddCreditModal(id) {
      this.add_id = id
      this.$refs['my-modal-addcredit'].show()
    },
    SubmitAddcredit() {
      if (!this.addcredit_amount) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'กรุณากรอกจำนวนเงิน',
            icon: 'SearchIcon',
            variant: 'warning',
          },
        })
      } else {
        this.show = true
        const obj = {
          id: this.add_id,
          amount: Number(this.addcredit_amount),
          note: this.addcredit_note,
        }
        this.$http
          .post('/bank/manual/addcredit', obj)
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.show = false
            this.addcredit_amount = ''
            this.add_id = ''
            this.Success(response.data.message)
            this.getData()
          })
        // eslint-disable-next-line no-unused-vars
          .catch(error => {
            this.show = false
            this.SwalError('ไม่สามารถทำรายการได้ในขณะนี้')
            this.openAddCreditModal(this.add_id)
          })
      }
    },
    addData() {
      this.edit_id = null
      this.$refs['my-modal'].show()
    },
    SubmitData() {
      this.show = true
      if (this.edit_id) {
        const obj = {
          id: this.edit_id,
          accname: this.accname,
          accno: this.accno,
          bankcode: this.bank_code,
        }
        this.$http
          .post('/bank/manual/update', obj)
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.show = false
            this.accname = ''
            this.accno = ''
            this.bank_code = ''
            this.edit_id = null
            this.Success('แก้ไขข้อมูลสำเร็จ')
            this.getData()
          })
          .catch(error => {
            this.show = false
            if (error.response.data.message.statusdesc) {
              this.SwalError(error.response.data.message.statusdesc)
            } else {
              this.SwalError(error.response.data.message)
            }
            this.editData()
          })
      } else {
        const obj = {
          accname: this.accname,
          accno: this.accno,
          bankcode: this.bank_code,
        }
        this.$http
          .post('/bank/manual/store', obj)
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.show = false
            this.accname = ''
            this.accno = ''
            this.bank_code = ''
            this.Success('เพิ่มข้อมูลสำเร็จ')
            this.getData()
          })
          .catch(error => {
            this.show = false
            if (error.response.data.message.statusdesc) {
              this.SwalError(error.response.data.message.statusdesc)
            } else {
              this.SwalError(error.response.data.message)
            }
            this.addData()
          })
      }
    },
    deleteData(id) {
      this.$swal({
        title: 'ลบบัญชี?',
        text: 'กรุณายืนยันการลบบัญชี',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          const params = {
            id,
          }
          this.$http
            .get('/bank/manual/delete', { params })
            .then(response => {
              this.getData()
              if (response.data) {
                this.$swal({
                  icon: 'success',
                  title: '<h3 style="color: #141414">ลบข้อมูลสำเร็จ</h3>',
                  text: '',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
            .catch(error => {
              console.log(error)
              this.$swal('เกิดข้อผิดพลาด', error.response.data.message, 'error')
            })
        } else {
          this.$swal('ยกเลิก', '', 'error')
        }
      })
    },
    editData(data) {
      this.accname = data.accname
      this.accno = data.accno
      this.bank_code = data.bankcode
      this.edit_id = data.id
      // console.log(this.accname, this.accno, this.bank_code)
      this.$refs['my-modal'].show()
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    Get_bank() {
      this.$http
        .get('/bank/list')
        .then(response => {
          this.banklist = response.data
        })
        .catch(error => console.log(error))
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
